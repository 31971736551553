/*
 |--------------------------------------------------------------------------
 | Gotham Font Family Stylesheet.
 |--------------------------------------------------------------------------
 |
 | Define the gotham font family.
 |
 */

/*@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 100;
    src: local('Gotham Thin Italic'), local('Gotham-ThinItalic'), url('static/assets/fonts/gotham/Gotham-ThinItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 200;
    src: local('Gotham Extra Light Italic'), local('Gotham-ExtraLightItalic'), url('static/assets/fonts/gotham/Gotham-ExtraLightItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 300;
    src: local('Gotham Light Italic'), local('Gotham-LightItalic'), url('static/assets/fonts/gotham/Gotham-LightItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 400;
    src: local('Gotham Book Italic'), local('Gotham-BookItalic'), url('static/assets/fonts/gotham/Gotham-BookItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 500;
    src: local('Gotham Medium Italic'), local('Gotham-MediumItalic'), url('static/assets/fonts/gotham/Gotham-MediumItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 700;
    src: local('Gotham Bold Italic'), local('Gotham-BoldItalic'), url('static/assets/fonts/gotham/Gotham-BoldItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 800;
    src: local('Gotham Ultra Italic'), local('Gotham-UltraItalic'), url('static/assets/fonts/gotham/Gotham-UltraItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 900;
    src: local('Gotham Black Italic'), local('Gotham-BlackItalic'), url('static/assets/fonts/gotham/Gotham-BlackItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 100;
    src: local('Gotham Thin'), local('Gotham-Thin'), url('static/assets/fonts/gotham/Gotham-Thin.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 200;
    src: local('Gotham Extra Light'), local('Gotham-ExtraLight'), url('static/assets/fonts/gotham/Gotham-ExtraLight.woff2') format('woff2');
}*/

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 300;
    src: local('Gotham Light'), local('Gotham-Light'), url('static/assets/fonts/gotham/Gotham-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    src: local('Gotham Book'), local('Gotham-Book'), url('static/assets/fonts/gotham/Gotham-Book.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    src: local('Gotham Medium'), local('Gotham-Medium'), url('static/assets/fonts/gotham/Gotham-Medium.woff2') format('woff2');
}

/*@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    src: local('Gotham Bold'), local('Gotham-Bold'), url('static/assets/fonts/gotham/Gotham-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 800;
    src: local('Gotham Ultra'), local('Gotham-Ultra'), url('static/assets/fonts/gotham/Gotham-Ultra.woff2') format('woff2');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 900;
    src: local('Gotham Black'), local('Gotham-Black'), url('static/assets/fonts/gotham/Gotham-Black.woff2') format('woff2');
}*/
