@tailwind base;

@layer base {
  /** BUTTONS **/
  .btn-primary,
  .btn-primary[type="submit"] {
      @apply border border-flushing-pink bg-flushing-pink text-white font-medium w-full rounded-full transition duration-75 ease-linear hover:bg-bloody-nose hover:border-bloody-nose disabled:border-navy disabled:bg-navy disabled:text-white disabled:opacity-20;
  }
  .btn-secondary,
  .btn-secondary[type="submit"] {
    @apply border border-navy bg-transparent text-navy font-medium w-full rounded-full transition duration-75 ease-linear hover:bg-navy hover:text-white disabled:border-navy disabled:bg-navy disabled:opacity-20;
  }
}