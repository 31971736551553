/*
 |--------------------------------------------------------------------------
 | Core Stylesheet.
 |--------------------------------------------------------------------------
 |
 | Define the core styles for the no scroll plugin.
 |
 */

html.no-scroll {
    overflow-x: hidden;
    overflow-y: hidden !important;
}

html.no-scroll body {
    overflow-x: hidden;
    overflow-y: hidden !important;
}
