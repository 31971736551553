/*
 |--------------------------------------------------------------------------
 | Icon Font Family Stylesheet.
 |--------------------------------------------------------------------------
 |
 | Define the icon font family.
 |
 */

@font-face {
    font-family: icon-font;
    src: url('static/assets/fonts/icon-font/icon-font.eot');
    src: url('static/assets/fonts/icon-font/icon-font.eot#iefix') format('embedded-opentype'), url('static/assets/fonts/icon-font/icon-font.woff2') format('woff2'), url('static/assets/fonts/icon-font/icon-font.woff') format('woff'), url('static/assets/fonts/icon-font/icon-font.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: icon-font !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before { content: '\e800'; }
.icon-camera:before { content: '\e801'; }
.icon-clock:before { content: '\e802'; }
.icon-contacts:before { content: '\e803'; }
.icon-cross:before { content: '\e804'; }
.icon-edit:before { content: '\e805'; }
.icon-home:before { content: '\e806'; }
.icon-leave:before { content: '\e807'; }
.icon-location:before { content: '\e808'; }
.icon-mail:before { content: '\e809'; }
.icon-measure:before { content: '\e80a'; }
.icon-message:before { content: '\e80b'; }
.icon-notifications:before { content: '\e80c'; }
.icon-paper:before { content: '\e80d'; }
.icon-phone:before { content: '\e80e'; }
.icon-settings:before { content: '\e80f'; }
.icon-trash:before { content: '\e810'; }
.icon-upload:before { content: '\e811'; }
.icon-van:before { content: '\e812'; }
.icon-weight:before { content: '\e813'; }
.icon-add-to-bag:before { content: '\e814'; }
.icon-ar:before { content: '\e815'; }
.icon-archive:before { content: '\e816'; }
.icon-bag:before { content: '\e817'; }
.icon-briefcase:before { content: '\e818'; }
.icon-error:before { content: '\e819'; }
.icon-valid:before { content: '\e81a'; }
.icon-arrow-left:before { content: '\e81b'; }
.icon-arrow-right:before { content: '\e81c'; }
.icon-box-outline:before { content: '\e81d'; }
.icon-box-solid:before { content: '\e81e'; }
.icon-camera-circle-solid:before { content: '\e81f'; }
.icon-chevron-down:before { content: '\e820'; }
.icon-chevron-left:before { content: '\e821'; }
.icon-chevron-right:before { content: '\e822'; }
.icon-chevron-up:before { content: '\e823'; }
.icon-credit-card-outline:before { content: '\e824'; }
.icon-credit-card-solid:before { content: '\e825'; }
.icon-cross-circle-outline:before { content: '\e826'; }
.icon-cross-circle-solid:before { content: '\e827'; }
.icon-dollar-circle-outline:before { content: '\e828'; }
.icon-info-circle-outline:before { content: '\e829'; }
.icon-plus-circle-outline:before { content: '\e82a'; }
.icon-plus-circle-solid:before { content: '\e82b'; }
.icon-plus:before { content: '\e82c'; }
.icon-profile-circle-solid:before { content: '\e82d'; }
.icon-profile-outline:before { content: '\e82e'; }
.icon-facebook:before { content: '\e82f'; }
.icon-instagram:before { content: '\e830'; }
.icon-pinterest:before { content: '\e831'; }
.icon-tiktok:before { content: '\e832'; }
.icon-twitter:before { content: '\e833'; }
.icon-help-circle-outline:before { content: '\e834'; }
.icon-help-circle-solid:before { content: '\e835'; }
