@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts/icon-font.css';
@import './fonts/gotham.css';
@import './theme.css';

:root {
    --popper-theme-background-color: #ffffff;
    --popper-theme-background-color-hover: #ffffff;
    --popper-theme-text-color: #000f51;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-color: #bfc3d3;
    --popper-theme-border-radius: 10px;
    --popper-theme-padding: 11px;
    --popper-theme-box-shadow: 0 13px 13px 0 rgba(0, 0, 0, 0.1);
    --slide-animation-duration: .1s;
    --fade-animation-duration: .3s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


input {
    caret-color: theme('colors.flushing-pink');
}

/* Transitions */

/** Slide **/
.slide-enter-active {
    transition: var(--slide-animation-duration);
    transform: translate(100%, 0);
}

.slide-leave-active {
    transition: var(--slide-animation-duration);
    position: absolute;
}

.slide-enter-to {
    transform: translate(0, 0);
}
.slide-leave-to {
    transform: translate(-100%, 0);
}

/** Slide Back **/
.slide-back-enter-active {
    transition: var(--slide-animation-duration);
    transform: translate(-100%, 0);
}

.slide-back-leave-active {
    transition: var(--slide-animation-duration);
    position: absolute;
}

.slide-back-enter-to {
    transform: translate(0, 0);
}
.slide-back-leave-to {
    transform: translate(100%, 0);
}

/** Zoom **/
.zoom-enter-active {
    animation: zoom 0.5s ease-in-out;
}

@keyframes zoom {
    0% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

/** Fade **/
.fade-enter-active {
    transition: var(--fade-animation-duration);
    opacity: 0;
}

.fade-leave-active {
    opacity: 0;
}

.fade-enter-to {
    opacity: 1;
}

/** Fade Scale **/

.fade-scale-enter-active {
    transition: var(--fade-animation-duration);
    opacity: 0;
    transform: scale(.8);
}

.fade-scale-enter-to {
    opacity: 1;
    transform: scale(1);
}

.fade-scale-leave-active {
    transition: var(--fade-animation-duration);
    opacity: 1;
    transform: scale(1);
}

.fade-scale-leave-to {
    opacity: 0;
    transform: scale(.8);
}

